
// Libraries
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component({})
export default class PriceTrendVote extends Vue {
  @Prop() progressBarText: string;
  @Prop({ required: true }) progressPercentage: number | string;
  @Prop({ default: "hsl(44deg 98% 60%)" }) progressColor: string;
  @Prop({ default: "default" }) variant: string;
}
