
import { Component, Vue, Prop, Emit } from "nuxt-property-decorator";

@Component({})
export default class LightboxMolecule extends Vue {
  @Prop() title: string;
  @Prop({ default: false }) visibilityState: boolean;
  @Prop({ default: false }) isFullscreen: boolean;

  @Emit()
  emitFalseVisibilityState(e) {
    return false;
  }
}
