
// Libraries
import {
  Component,
  Prop,
  Vue,
  Watch,
  namespace,
} from "nuxt-property-decorator";
import {
  ButtonText,
  InputFieldLabel,
  InputPlaceholder,
  LabelAdditives,
  Regex,
} from "~/operations/messages";
import isEmail from "validator/lib/isEmail";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import ProgressBar from "~/components/molecules/display/ProgressBar.vue";

import { getMkLogin } from "@/operations";
const EsyoilModule = namespace("esyoil");

@Component({
  components: {
    RouterLink,
    ProgressBar,
    PrivacyNotice: () =>
      import("~/components/molecules/display/PrivacyNotice.vue"),
  },
})
export default class NewsletterSignupOrganism extends Vue {
  @Prop({ default: "Der esyoil-Newsletter" }) title: string;

  @EsyoilModule.Action("postNewsletterSubscription")
  postNewsletterSubscription;

  @EsyoilModule.State("newsletterSignupStatus")
  newsletterSignupStatus;

  @EsyoilModule.State("nlErrorMessage")
  nlErrorMessage;

  ButtonText = ButtonText;
  InputFieldLabel = InputFieldLabel;
  InputPlaceholder = InputPlaceholder;
  LabelAdditives = LabelAdditives;
  Regex = Regex;
  errorClass = "";

  emailInputSupportMessage = "";
  zipcodeInputSupportMessage = "";

  newsletterUserInput = {
    email: "",
    zipcode: "",
  };

  signupProcess = {
    buttonText: ButtonText.startProcess,
    descriptionText:
      "Jetzt für unseren Newsletter anmelden und stets bestens informiert bleiben!",
    icon: "envelope-open-solid.svg",
    step: 0,
    progressPercentage: 0,
  };

  mounted() {
    this.tryLocalStorageUserZipCode();
  }

  @Watch("newsletterSignupStatus")
  async onnewsletterSignupStatusChange() {
    await this.forwardSignupProccess();
  }

  tryLocalStorageUserZipCode() {
    this.newsletterUserInput.zipcode = localStorage.getItem("userZipCode");
  }

  setupSignupTexts(descriptionText: string, buttonText: string) {
    this.signupProcess.descriptionText = descriptionText;
    this.signupProcess.buttonText = buttonText;
  }

  changeStepIcon(icon = "envelope-open-solid.svg") {
    this.signupProcess.icon = icon;
  }

  setProgressPercentage(progressPercentage: number) {
    this.signupProcess.progressPercentage = progressPercentage;
  }

  returnEmailValidation(mailInput: string) {
    if (!isEmail(mailInput)) {
      this.emailInputSupportMessage = "Fehlerhafte E-Mail-Adresse";
      return false;
    } else this.emailInputSupportMessage = "";
  }

  returnZipCodeVadilation(zipcodeInput: string) {
    if (zipcodeInput.length !== 5) {
      this.zipcodeInputSupportMessage = "Fehlerhafte Postleitzahl";
      return false;
    } else this.zipcodeInputSupportMessage = "";
  }

  async forwardSignupProccess(forward = true) {
    if (forward === false) this.signupProcess.step -= 1;
    if (
      this.signupProcess.step === 0 &&
      this.returnEmailValidation(this.newsletterUserInput.email) === false
    )
      return;
    if (
      this.signupProcess.step === 1 &&
      this.returnZipCodeVadilation(this.newsletterUserInput.zipcode) === false
    )
      return;
    if (forward === true) this.signupProcess.step += 1;

    if (this.signupProcess.step === 0) {
      this.setupSignupTexts(
        "Jetzt für unseren Newsletter anmelden und stets bestens Informiert bleiben!",
        ButtonText.next,
      );
      this.changeStepIcon("envelope-open-solid.svg");
      this.setProgressPercentage(0);
    }
    if (this.signupProcess.step === 1) {
      this.setupSignupTexts(
        "Bitte verraten Sie uns Ihre PLZ, um sich Ihre individuellen Preisinformationen zu sichern.",
        ButtonText.signup,
      );
      this.changeStepIcon("map-marker-alt-solid.svg");
      this.setProgressPercentage(50);
    }
    if (this.signupProcess.step === 2) {
      this.setupSignupTexts(
        "Ihre Newsletter-Anmeldung wird gerade verarbeitet, es kann sich nur um Sekunden handeln...",
        "",
      );
      this.changeStepIcon("hourglass-half-solid.svg");
      await this.postNewsletterSubscription(this.newsletterUserInput);
      this.signupProcess.step += 1;
    }
    if (this.signupProcess.step === 3) {
      this.setProgressPercentage(100);

      // Fehlerfall
      if (!this.newsletterSignupStatus && this.nlErrorMessage) {
        this.changeStepIcon("times-solid.svg");
        this.setupSignupTexts("", "");
        this.errorClass = "red-icon";
      } else {
        this.setupSignupTexts(
          "In Kürze erhalten Sie von uns eine E-Mail, bitte bestätigen den darin enthaltenen Anmelde-Link.",
          "",
        );
        this.changeStepIcon("check-circle-solid.svg");
      }
    }
  }

  get _getMkLogin() {
    return getMkLogin();
  }

  get shouldShowProgressBar(): boolean {
    return this.signupProcess.step < 3 && !this.newsletterSignupStatus;
  }

  get progressBarClass(): string {
    return this.signupProcess.step === 1
      ? "progress-bar-no-bottom-margin"
      : "progress-bar";
  }

  get shouldShowPrivacyNotice(): boolean {
    return this.signupProcess.step === 1;
  }
}
