
// Librarys
import {
  Component,
  Emit,
  Prop,
  Vue,
  Watch,
  namespace,
} from "nuxt-property-decorator";

import {
  ButtonText,
  InputFieldLabel,
  InputPlaceholder,
  LabelAdditives,
  Regex,
} from "~/operations/messages";
import isEmail from "validator/lib/isEmail";

// Components
import { getMkLogin } from "@/operations";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import LightBox from "~/components/molecules/display/LightBox.vue";
import ProgressBar from "~/components/molecules/display/ProgressBar.vue";

// Mixins
import { returnPriceFormatDE } from "~/mixins/formatting";

const EsyoilModule = namespace("esyoil");
const FederalStatesModule = namespace("federalstates");
const OilPriceModule = namespace("oilprice");

@Component({
  components: {
    ProgressBar,
    RouterLink,
    LightBox,
    PrivacyNotice: () =>
      import("~/components/molecules/display/PrivacyNotice.vue"),
  },
  methods: {
    returnPriceFormatDE,
  },
})
export default class PriceNotification extends Vue {
  @Prop({ default: "Der esyoil-Preiswecker" }) title: string;
  @Prop({ default: "Der Preiswecker für" }) titleExitIntent: string;
  @Prop({ default: "default" }) variantBox: "default" | "exitent";
  @Prop({ required: false }) cityName: string;
  @Prop({ required: false }) cityZip: string;
  @Prop({ required: false }) oilprice: any;
  @Emit()
  emitFalseVisibilityState(e) {
    return false;
  }

  @EsyoilModule.Action("postPriceNotification")
  _postPriceNotification;

  priceNotificationSignupStatus = false;

  @EsyoilModule.State("pwErrorMessage")
  pwErrorMessage;

  @FederalStatesModule.State("city")
  city: string;

  @OilPriceModule.State("stateAssetsPrice")
  stateAssetsPrice: any;

  ButtonText = ButtonText;
  InputFieldLabel = InputFieldLabel;
  InputPlaceholder = InputPlaceholder;
  LabelAdditives = LabelAdditives;
  Regex = Regex;
  errorClass = "";

  signupProcess = {
    buttonText: ButtonText.startProcess,
    descriptionText:
      "Jetzt den esyoil-Preiswecker stellen und zum richtigen Zeitpunkt kaufen.",
    icon: "bell-solid.svg",
    step: 0,
    progressPercentage: 0,
  };

  priceInputSupportMessage = "";
  emailInputSupportMessage = "";
  zipcodeInputSupportMessage = "";
  priceSupportMessage = "";
  amountInputSupportMessage = "";

  showSignupOverlay = false;

  selectStationsOptions: Array<{ name: string; value: number }> = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
    { name: "4", value: 4 },
    { name: "5", value: 5 },
    { name: "6", value: 6 },
    { name: "7", value: 7 },
    { name: "8", value: 8 },
    { name: "9", value: 9 },
  ];

  priceNotificationUserInput = {
    price: "",
    amount: 3000,
    mail: "",
    zip: "",
    unloading_points: "1",
    origin: "",
  };

  mounted() {
    this.presetUserDataInput();
  }

  @Watch("priceNotificationSignupStatus")
  async onPriceNotificationSignupStatusChange() {
    await this.forwardSignupProccess();
  }

  presetUserDataInput() {
    this.priceNotificationUserInput.zip = localStorage.getItem("userZipCode");
  }

  setupSignupTexts(descriptionText: string, buttonText: string) {
    this.signupProcess.descriptionText = descriptionText;
    this.signupProcess.buttonText = buttonText;
  }

  changeStepIcon(icon = "envelope-open-solid.svg") {
    this.signupProcess.icon = icon;
  }

  setProgressPercentage(progressPercentage: number) {
    this.signupProcess.progressPercentage = progressPercentage;
  }

  returnEmailValidation(mailInput: string) {
    if (!isEmail(mailInput)) {
      this.emailInputSupportMessage = "Fehlerhafte E-Mail-Adresse";
      return false;
    } else this.emailInputSupportMessage = "";
  }

  returnZipCodeVadilation(zipcodeInput: string) {
    if (zipcodeInput.length !== 5) {
      this.zipcodeInputSupportMessage = "Fehlerhafte Postleitzahl";
      return false;
    } else this.zipcodeInputSupportMessage = "";
  }

  returnPriceInputValidation(priceInput: string) {
    if (priceInput === "") {
      this.priceSupportMessage = "Bitte geben Sie einen Preis ein";
      return false;
    } else this.priceSupportMessage = "";
  }

  returnAmountValidation(amountInput: number) {
    if (amountInput < 500) {
      this.amountInputSupportMessage = "500 Liter Mindestmenge";
      return false;
    } else this.amountInputSupportMessage = "";
  }

  async forwardSignupProccess(forward = true) {
    if (forward === false) this.signupProcess.step -= 1;
    if (
      this.signupProcess.step === 0 &&
      this.returnEmailValidation(this.priceNotificationUserInput.mail) === false
    )
      return;
    if (
      this.signupProcess.step === 1 &&
      this.returnPriceInputValidation(this.priceNotificationUserInput.price) ===
        false
    )
      return;
    if (
      this.signupProcess.step === 2 &&
      this.returnAmountValidation(this.priceNotificationUserInput.amount) ===
        false
    )
      return;
    if (
      this.signupProcess.step === 3 &&
      this.returnZipCodeVadilation(this.priceNotificationUserInput.zip) ===
        false
    )
      return;
    if (forward === true) this.signupProcess.step += 1;

    if (this.signupProcess.step === 0) {
      this.setupSignupTexts(
        "Jetzt den esyoil-Preiswecker stellen und zum richtigen Zeitpunkt kaufen.",
        ButtonText.next,
      );
      this.changeStepIcon("bell-solid.svg");
      this.setProgressPercentage(0);
    }
    if (this.signupProcess.step === 1) {
      this.setupSignupTexts(
        "Bei welchem Preis pro 100 Liter möchten Sie gerne von uns benachrichtigt werden?",
        ButtonText.next,
      );
      this.changeStepIcon("piggy-bank-solid.svg");
      this.setProgressPercentage(20);
    }
    if (this.signupProcess.step === 2) {
      this.setupSignupTexts(
        "Wieviel Liter Heizöl werden Sie benötigen? 3000 Liter entsprechen ungefähr dem Durchschnitt.",
        ButtonText.next,
      );
      this.changeStepIcon("tint-solid.svg");
      this.setProgressPercentage(40);
    }
    if (this.signupProcess.step === 3) {
      this.setupSignupTexts(
        "Je nach Ort variieren die Preise, bitte teilen Sie uns deshalb Ihre Postleitzahl mit.",
        ButtonText.next,
      );
      this.changeStepIcon("map-marker-alt-solid.svg");
      this.setProgressPercentage(60);
    }
    if (this.signupProcess.step === 4) {
      this.setupSignupTexts(
        "Wieviele Entladestellen sollen vom Lieferanten angefahren werden?",
        ButtonText.signup,
      );
      this.changeStepIcon("dolly-solid.svg");
      this.setProgressPercentage(80);
    }
    if (this.signupProcess.step === 5) {
      this.setupSignupTexts(
        "Ihre Preiswecker-Anmeldung wird gerade verarbeitet, es kann sich nur um Sekunden handeln...",
        "",
      );
      this.changeStepIcon("hourglass-half-solid.svg");
      if (this.variantBox === "exitent") {
        this.priceNotificationUserInput.origin = "exitent";
      }
      this.signupProcess.step += 1;
      this.setProgressPercentage(90);
      const result = await this._postPriceNotification(
        this.priceNotificationUserInput,
      );
      this.priceNotificationSignupStatus = result;
    }

    if (this.signupProcess.step === 6) {
      this.setProgressPercentage(100);

      // Fehlerfall
      if (!this.priceNotificationSignupStatus && this.pwErrorMessage) {
        this.changeStepIcon("times-solid.svg");
        this.setupSignupTexts("", "");
        this.errorClass = "red-icon";
      } else {
        this.setupSignupTexts(
          "In Kürze erhalten Sie von uns eine E-Mail, bitte bestätigen den darin enthaltenen Anmelde-Link.",
          "",
        );
        this.changeStepIcon("check-circle-solid.svg");
      }
    }
  }

  get _getMkLogin() {
    return getMkLogin();
  }

  get shouldShowProgressBar(): boolean {
    return this.signupProcess.step < 6 && !this.priceNotificationSignupStatus;
  }

  get progressBarClass(): string {
    return this.signupProcess.step === 4
      ? "progress-bar-no-bottom-margin"
      : "progress-bar";
  }

  get shouldShowPrivacyNotice(): boolean {
    return this.signupProcess.step === 4;
  }
}
